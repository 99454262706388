'use client';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { cn } from "@/lib/utils";
import { Modal, ModalBody, ModalContent, ModalFooter } from "../ui/animated-modal";

const GoogleReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fonction pour récupérer les avis
    const fetchReviews = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/reviews/google-reviews`);
            setReviews(response.data.reviews || []);
            setError(null);
        } catch (err) {
            setError(err.message || 'Une erreur inconnue est survenue.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    if (error) {
        return (
            <div className="bg-red-100 text-red-600 rounded-lg p-4 mx-4 lg:mx-20 mt-10 text-center">
                <p>Erreur lors de la récupération des avis : {error}</p>
                <button
                    onClick={fetchReviews}
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                >
                    Réessayer
                </button>
            </div>
        );
    }

    return (
        <div className="bg-white shadow-lg rounded-2xl p-8 mx-4 lg:mx-20 mt-20 mb-10">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Avis <span className="text-[#4285F4]">G</span><span className="text-[#EA4335]">o</span><span className="text-[#FBBC05]">o</span><span className="text-[#4285F4]">g</span><span className="text-[#34A853]">l</span><span className="text-[#EA4335]">e</span>
            </h2>

            {/* Spinner dégradé pendant le chargement */}
            {loading ? (
                <div className="flex justify-center items-center mt-10">
                    <div className="relative w-16 h-16 animate-spin">
                        <div
                            className="absolute inset-0 rounded-full border-t-4"
                            style={{
                                borderTopColor: '#4285F4', // Bleu Google
                                borderRightColor: '#EA4335', // Rouge Google
                                borderBottomColor: '#FBBC05', // Jaune Google
                                borderLeftColor: '#34A853', // Vert Google
                                borderStyle: 'solid',
                                borderWidth: '4px',
                            }}
                        ></div>
                    </div>
                </div>
            ) : reviews.length > 0 ? (
                <div className="relative overflow-hidden">
                    <Marquee>
                        {reviews.map((review, index) => (
                            <ReviewCard key={index} review={review} />
                        ))}
                    </Marquee>
                </div>
            ) : (
                <p className="text-center text-gray-500">Aucun avis pour l&apos;instant.</p>
            )}
        </div>
    );
};

const Marquee = ({ children }) => {
    return (
        <div className="relative flex overflow-hidden">
            {/* Premier ensemble de cartes */}
            <div className="flex animate-marquee">
                {children}
            </div>
            {/* Deuxième ensemble de cartes (dupliqué) */}
            <div className="flex absolute top-0 animate-marquee2">
                {children}
            </div>
        </div>
    );
};

const ReviewCard = ({ review }) => {
    return (
        <div className="w-[300px] p-2 shrink-0"> {/* Largeur fixe sans padding */}
            <Modal>
                <figure
                    className={cn(
                        "w-full rounded-xl p-4",
                        "border border-gray-200 bg-gradient-to-br from-gray-50 to-gray-100",
                        "hover:shadow-md transition-shadow duration-300"
                    )}
                >
                    <div className="flex flex-row items-center gap-4 mb-4">
                        <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-gray-600 font-bold text-xl">
                                {review.authorName ? review.authorName[0] : '?'}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <figcaption className="text-lg font-semibold text-gray-800">
                                {review.authorName}
                            </figcaption>
                            <p className="text-sm text-gray-500">{review.relativeTime}</p>
                        </div>
                    </div>
                    <div className="text-yellow-500 text-lg font-semibold mb-4">
                        {'★'.repeat(review.rating)}
                        {'☆'.repeat(5 - review.rating)}
                    </div>
                    <blockquote className="text-gray-700 text-sm leading-relaxed line-clamp-3">
                        {review.text}
                    </blockquote>
                    {review.text.length > 100 && (
                        <button className="mt-2 text-sm text-[#4285F4] hover:text-[#EA4335] transition-colors duration-200">
                            Lire la suite...
                        </button>
                    )}
                </figure>

                <ModalBody>
                    <ModalContent>
                        <div className="p-6">
                            <div className="flex items-center gap-4 mb-6">
                                <div className="w-14 h-14 bg-gray-200 rounded-full flex items-center justify-center">
                                    <span className="text-gray-600 font-bold text-2xl">
                                        {review.authorName ? review.authorName[0] : '?'}
                                    </span>
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                                        {review.authorName}
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        {review.relativeTime}
                                    </p>
                                </div>
                            </div>
                            <div className="text-yellow-500 text-xl font-semibold mb-4">
                                {'★'.repeat(review.rating)}
                                {'☆'.repeat(5 - review.rating)}
                            </div>
                            <blockquote className="text-gray-700 dark:text-gray-300 text-base leading-relaxed">
                                {review.text}
                            </blockquote>
                        </div>
                    </ModalContent>
                    <ModalFooter />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default GoogleReviews;








