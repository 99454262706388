'use client';

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

// Création du contexte en dehors des composants
const ModalContext = React.createContext();

const Modal = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    // On passe handleClose aux enfants via le contexte
    const contextValue = React.useMemo(() => ({ handleClose }), []);

    return (
        <>
            <div onClick={handleOpen}>{children[0]}</div>
            {isOpen && createPortal(
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleClose}
                        className="fixed inset-0 z-50 bg-black/60 flex items-center justify-center p-4"
                    >
                        <motion.div
                            initial={{ scale: 0.95, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.95, opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                            className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-xl w-full max-w-lg"
                        >
                            <ModalContext.Provider value={contextValue}>
                                {children[1]}
                            </ModalContext.Provider>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>,
                document.body
            )}
        </>
    );
};

const ModalContent = ({ children, className }) => {
    return (
        <div className={className}>
            {children}
        </div>
    );
};

const ModalBody = ({ children }) => {
    return <div>{children}</div>;
};

const ModalFooter = ({ children, className }) => {
    const { handleClose } = React.useContext(ModalContext);

    return (
        <div className={`p-4 bg-gray-50 dark:bg-gray-900 flex justify-end gap-2 ${className}`}>
            <button
                onClick={handleClose}
                className="px-4 py-2 bg-gray-200 text-black dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md text-sm hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            >
                Fermer
            </button>
            {children}
        </div>
    );
};

export { Modal, ModalBody, ModalContent, ModalFooter }; 